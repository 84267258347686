import { HeatMapBase } from '@/components/visualisations/HeatMap';
import {
  playerDefensiveActivityAtom,
  unwrappedPlayerDefensiveActivityAtom,
} from '@/atoms/queries/player/playerEventData';
import { CardBody } from '@statsbomb/kitbag-components';
import { useStatus } from '@/hooks/useStatus';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { useAtomValue } from 'jotai';
import { CardHeightWrapper } from '@/components/card/CardHeightWrapper';

export const PlayerDefensiveActivity = () => {
  const events = useAtomValue(unwrappedPlayerDefensiveActivityAtom);
  const status = useLoadableStatus(playerDefensiveActivityAtom);
  const statusProps = useStatus('general', status);

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        <HeatMapBase eventsForMetric={events} />
      </CardBody>
    </CardHeightWrapper>
  );
};
