import { Table } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import {
  eventsDataDefaultColumnsAtom,
  eventsDataVisibleColumnsAtom,
  unwrappedEventsDataVisibleColumnsAtom,
} from '@/atoms/dataLocker/events/table';
import { DataTableColumn } from '@/types/table';
import { NestedObject, NestedObjectPromiseAtom } from '@/types/object';
import {
  alignTableCell,
  getFormatRule,
  getHighlightedColumnIndex,
  getValidColumnKey,
  isLowerBetter,
  translateValue,
} from '@/utils/table';
import { useCurrentlySelectedVideoId } from '@/hooks/useCurrentlySelectedVideoId';
import { PromiseAtom } from '@/types/atom';
import { TablePagination } from '@/components/tables/TablePagination';
import { tableColumnEntitiesAtom, tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { Suspense } from 'react';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { ErrorBoundary } from '@sentry/react';
import { useSortTable } from '@/hooks/useSortTable';
import { hasVideoLicenceAtom } from '@/atoms/user';
import { StatusTranslationKey } from '@/types/status';
import { loadable } from 'jotai/utils';
import { useVisDisplayMode } from '@/hooks/useVisDisplayMode';
import { getVideoIdsFromEvent } from '@/utils/video';
import { ScopeProvider } from 'jotai-scope';
import { useTableColumns } from '@/hooks/useTableColumns';
import { PlayVideoButton } from '../video/PlayVideoButton';
import { CellValueToDisplay } from './CellValueToDisplay';
import { HasVideoAccess } from '../video/HasVideoAccess';
import { TableWrapper } from './TableWrapper';
import { ContentState } from '../contentState/ContentState';
import { ColumnControlsWrapper } from '../controls/ColumnControlsWrapper';
import { EditColumnsButton } from '../button/EditColumnsButton';
import { MoreOptionsMenu } from '../menu/MoreOptionsMenu/MoreOptionsMenu';
import { ExportCsvMenuItem } from '../export/ExportCsvMenuItem';

export const EventsTableBase = ({
  events = [],
  availableColumns,
  isLoading = false,
  isVideoColumnVisible,
  hasError = false,
}: {
  events?: NestedObject[];
  availableColumns?: DataTableColumn[];
  isLoading?: boolean;
  isVideoColumnVisible?: boolean;
  hasError?: boolean;
}) => {
  useTableColumns(['competition', 'season', 'game', 'team', 'player', 'event']);
  const { t } = useTranslation('events');
  const { orderBy, handleSort, getSortedState } = useSortTable();
  const visibleColumns = useAtomValue(unwrappedEventsDataVisibleColumnsAtom);

  const eventColumnSortKeys = visibleColumns.map(key => getValidColumnKey(key));

  const sortedColumnIndex = orderBy ? eventColumnSortKeys.findIndex(key => key === orderBy) : -1;
  const highlightedColumnIndex = getHighlightedColumnIndex(sortedColumnIndex, !!isVideoColumnVisible);

  const currentlySelectedVideoId = useCurrentlySelectedVideoId();

  const [visDisplayMode] = useVisDisplayMode();

  const selectedRow = () => {
    const rowIndex = events.findIndex(event => getVideoIdsFromEvent(event).videoEventId === currentlySelectedVideoId);
    return rowIndex + 1;
  };

  const hasData = events.length > 0;

  return (
    <>
      {visDisplayMode === 'both' && (
        <ColumnControlsWrapper>
          <EditColumnsButton
            visibleColumnsAtom={eventsDataVisibleColumnsAtom}
            defaultColumnsAtom={eventsDataDefaultColumnsAtom}
          />
          <MoreOptionsMenu>
            <ExportCsvMenuItem />
          </MoreOptionsMenu>
        </ColumnControlsWrapper>
      )}

      <TableWrapper>
        <div className={hasData ? 'overflow-x-auto' : 'overflow-x-hidden'}>
          <Table
            isHeadSticky
            withBorder={false}
            highlightedColumn={highlightedColumnIndex}
            selectedRows={[selectedRow()]}
          >
            <Table.Head>
              <Table.Row>
                <HasVideoAccess>
                  <Table.HeaderCell size="regular" />
                </HasVideoAccess>
                {eventColumnSortKeys.map((key, index) => {
                  const formatRule = getFormatRule(key, availableColumns);

                  return (
                    <Table.HeaderCell
                      key={key}
                      textAlign={alignTableCell(formatRule)}
                      sortCb={() => handleSort(key, isLowerBetter(formatRule))}
                      sortState={getSortedState(key)}
                      size="regular"
                    >
                      <span data-testid={`column-${index}`}>{t(`keys.${key}`)}</span>
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Head>
            {hasData && (
              <Table.Body>
                {events.map(event => (
                  // TODO (PPC-324: once we've completely moved over to new style events, remove the fallback here)
                  <Table.Row key={String(event['event.eventId'])}>
                    <HasVideoAccess>
                      <Table.DataCell>
                        <PlayVideoButton
                          eventId={String(event['event.eventId'])}
                          uuid={String(
                            event['event.attributes.originatingArqamEventId'] || event['event.attributes.arqamEventId'],
                          )}
                        />
                      </Table.DataCell>
                    </HasVideoAccess>
                    {visibleColumns.map(key => {
                      const columnRule = getFormatRule(key, availableColumns);
                      const value = translateValue(key, event[key], columnRule, t);
                      return <CellValueToDisplay key={key} eventKey={key} value={value} columnRule={columnRule} />;
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          </Table>
        </div>
        {!isLoading && !hasData && <ContentState status={StatusTranslationKey.NO_DATA} />}
        {isLoading && <ContentState status={StatusTranslationKey.LOADING} />}
        {hasError && <ContentState status={StatusTranslationKey.ERROR} />}
      </TableWrapper>
    </>
  );
};

const EventsTableWithData = ({ eventsAtom }: { eventsAtom: PromiseAtom<NestedObject[]> }) => {
  const loadableEvents = useAtomValue(loadable(eventsAtom));
  const availableColumns = useAtomValue(tableColumnsAtom);
  const isVideoColumnVisible = useAtomValue(hasVideoLicenceAtom);

  const { state } = loadableEvents;
  if (state === 'loading') return <EventsTableBase {...{ availableColumns, isVideoColumnVisible }} isLoading />;

  if (state === 'hasError') return <EventsTableBase {...{ availableColumns, isVideoColumnVisible }} hasError />;

  return <EventsTableBase {...{ events: loadableEvents.data, availableColumns, isVideoColumnVisible }} />;
};

export const EventsTableWithPagination = ({
  eventsAtom,
  dataLengthAtom,
}: {
  eventsAtom: NestedObjectPromiseAtom;
  dataLengthAtom: PromiseAtom<number>;
}) => (
  <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
    <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
      <Suspense fallback={<EventsTableBase isLoading />}>
        <EventsTableWithData {...{ eventsAtom }} />
      </Suspense>
      <TablePagination {...{ dataLengthAtom }} />
    </ErrorBoundary>
  </ScopeProvider>
);
