import { useTranslation } from 'react-i18next';
import { Typography } from '@statsbomb/kitbag-components';
import { ContentState } from '@/components/contentState/ContentState';
import { DashboardWidgets } from '@/components/dashboard/DashboardWidgets';
import { StatusTranslationKey } from '@/types/status';
import { isNullish } from '@/utils/general';
import { DashboardWidgetConfig } from '@/types/widget';
import { Nullable } from '@/types/generic';

export const DashboardContent = ({
  entityId,
  dashboardWidgets,
}: {
  entityId: Nullable<number>;
  dashboardWidgets: DashboardWidgetConfig[];
}) => {
  const { t } = useTranslation('navigation');
  const heading = t('secondary.dashboard');

  return (
    <div className="flex flex-col gap-2">
      <div className="bg-canvas-tertiary-main w-full flex items-center justify-between px-4 py-3">
        <Typography variant="headingRegular">{heading}</Typography>
      </div>
      {isNullish(entityId) ? (
        <ContentState status={StatusTranslationKey.ERROR} />
      ) : (
        <DashboardWidgets entityId={entityId} widgets={dashboardWidgets} />
      )}
    </div>
  );
};
