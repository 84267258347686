import { useShouldShowHiddenFeature } from '@/hooks/useShouldShowHiddenFeature';
import { PageSubNav } from './PageSubNav';

export const TeamNav = () => {
  const shouldShowHiddenFeature = useShouldShowHiddenFeature();

  // TODO (PPC-1662: Unhide team dashboard)
  /* istanbul ignore next */
  const dashboardRoute = shouldShowHiddenFeature
    ? [
        {
          translation: { key: 'secondary.dashboard' },
          route: 'dashboard',
        },
      ]
    : [];

  return (
    <PageSubNav
      items={[
        ...dashboardRoute,
        {
          translation: { key: 'secondary.radar' },
          route: 'radar',
        },
        {
          translation: { key: 'secondary.pitchVis' },
          route: 'vis',
        },
        {
          translation: { key: 'secondary.squad' },
          route: 'squad',
        },
        {
          translation: { key: 'secondary.games' },
          route: 'matches',
        },
      ]}
    />
  );
};
