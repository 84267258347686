import { isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { useAtom, useSetAtom } from 'jotai';
import { navigationOpenAtom } from '@/atoms/navigation';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { SideNavigation, SideNavigationHeader } from '@statsbomb/kitbag-components';
import { useNavigationItems } from '@/hooks/useNavigationItems';
import { ReactNode } from 'react';
import { SupportLinks } from '@/components/topBar/SupportLinks';
import { EmptySideNavLinks, MainSideNavLinks, SideNavLinkProps } from '@/components/navigation/SideNavLinks';
import { EmptySideNavFooterLinks, SideNavFooterLinks } from '@/components/navigation/SideNavFooterLinks';
import LogoText from '../../images/logos/hudlStatsbombTextLogo.svg';
import LogoIcon from '../../images/logos/hudlLogo.svg';

const LogoLink = ({ id, children }: { id: string; children: ReactNode }) => {
  const navigationItems = useNavigationItems();
  const defaultTeamLink = navigationItems.primary.find(item => item.icon === 'TeamSoccer')!;

  return (
    <Link to={defaultTeamLink.path}>
      <div data-testid={`logo-link-${id}`}>{children}</div>
    </Link>
  );
};

const SideNavBase = ({
  SideNavMainComponent,
  SideNavFooterComponent,
}: {
  SideNavMainComponent: React.FC<SideNavLinkProps>;
  SideNavFooterComponent: React.FC<{ isTouch: boolean }>;
}) => {
  const setIsSettingsPanelOpen = useSetAtom(isSettingsPanelOpenAtom);

  const [isNavOpen, setIsNavOpen] = useAtom(navigationOpenAtom);
  const { pathname } = useLocation();
  const theme = useTheme();
  const isTouch = theme.mqMatch('md');
  const isNavOpenState = isTouch ? isNavOpen : false;
  // The split always has 3 elements, an empty string, the language and the section
  const pathSection = String(pathname.split('/').at(2));

  return (
    <SideNavigation version={2} isOpen={isNavOpenState} isTouch={isTouch}>
      <SideNavigationHeader
        isOpen={isNavOpenState}
        isTouch={isTouch}
        logoIcon={
          <LogoLink id="icon">
            <LogoIcon />
          </LogoLink>
        }
        logoText={
          <LogoLink id="text">
            <LogoText />
          </LogoLink>
        }
        onClick={isTouch ? () => setIsNavOpen(!isNavOpen) : undefined}
        optionalControl={
          <div className="relative">
            <SupportLinks isMobile />
          </div>
        }
      />
      <SideNavMainComponent {...{ pathSection, isTouch, setIsNavOpen, setIsSettingsPanelOpen }} />
      <SideNavFooterComponent isTouch={isTouch} />
    </SideNavigation>
  );
};

export const SideNav = () => (
  <SideNavBase SideNavMainComponent={MainSideNavLinks} SideNavFooterComponent={SideNavFooterLinks} />
);
export const EmptySideNav = () => (
  <SideNavBase SideNavMainComponent={EmptySideNavLinks} SideNavFooterComponent={EmptySideNavFooterLinks} />
);
