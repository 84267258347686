import { unwrappedSelectedPlayerNameAtom } from '@/atoms/player/info';
import { PageTitle } from '@/components/head/PageTitle';
import { PlayerLayoutWithFilters } from '@/components/layout/player/PlayerLayoutWithFilters';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { playerIdAtom } from '@/atoms/player/player';
import { playerDashboardWidgetsAtom } from '@/atoms/dashboardTemplate/dashboardTemplate';
import { DashboardContent } from '@/components/layout/DashboardContent';

export const PlayerDashboardPage = () => {
  const { t } = useTranslation('navigation');
  const playerName = useAtomValue(unwrappedSelectedPlayerNameAtom);
  const heading = t('secondary.dashboard');
  const pageTitle = useEntityPageTitle(heading, playerName);
  const playerId = useAtomValue(playerIdAtom);
  const playerDashboardWidgets = useAtomValue(playerDashboardWidgetsAtom);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <PlayerLayoutWithFilters>
        <DashboardContent entityId={playerId} dashboardWidgets={playerDashboardWidgets} />
      </PlayerLayoutWithFilters>
    </>
  );
};
