import { useAtomValue } from 'jotai';
import { CardBody } from '@statsbomb/kitbag-components';
import { teamShotMarkersAtom, unwrappedTeamShotMarkersAtom } from '@/atoms/queries/team/teamEventData';
import { useStatus } from '@/hooks/useStatus';
import { ShotMap } from '@/components/visualisations/ShotMap/ShotMap';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { CardHeightWrapper } from '@/components/card/CardHeightWrapper';

export const TeamShotMap = () => {
  const unwrappedTeamShotMarkers = useAtomValue(unwrappedTeamShotMarkersAtom);
  const status = useLoadableStatus(teamShotMarkersAtom);
  const statusProps = useStatus('general', status);

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        <ShotMap events={unwrappedTeamShotMarkers} />
      </CardBody>
    </CardHeightWrapper>
  );
};
