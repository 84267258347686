import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { PageTitle } from '@/components/head/PageTitle';
import { TeamLayoutWithFilters } from '@/components/layout/team/TeamLayoutWithFilters';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { teamIdAtom } from '@/atoms/team/team';
import { teamDashboardWidgetsAtom } from '@/atoms/dashboardTemplate/dashboardTemplate';
import { DashboardContent } from '@/components/layout/DashboardContent';

export const TeamDashboardPage = () => {
  const { t } = useTranslation('navigation');
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);
  const heading = t('secondary.dashboard');
  const pageTitle = useEntityPageTitle(heading, teamName);
  const teamId = useAtomValue(teamIdAtom);
  const teamDashboardWidgets = useAtomValue(teamDashboardWidgetsAtom);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <TeamLayoutWithFilters>
        <DashboardContent entityId={teamId} dashboardWidgets={teamDashboardWidgets} />
      </TeamLayoutWithFilters>
    </>
  );
};
