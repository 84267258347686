import { eventDataFilterParamsAtom, eventsDataCountAtom } from '@/atoms/queries/eventData';
import { EventDataControls } from '@/components/controls/EventsDataControls';
import { DataLockerEventsTable } from '@/components/dataLocker/DataLockerEventsTable';
import { PageTitle } from '@/components/head/PageTitle';
import { DataLockerLayoutWithFilters } from '@/components/layout/dataLocker/DataLockerLayoutWithFilters';
import { VisDisplaySection } from '@/components/section/VisDisplaySection';
import { EventCountInfoBar } from '@/components/visualisations/EventCountInfoBar';
import { PitchVisualisation } from '@/components/visualisations/PitchVisualisation/PitchVisualisation';
import { useAutoResetPagination } from '@/hooks/useAutoResetPagination';
import { useTableColumns } from '@/hooks/useTableColumns';
import { VisDisplayMode } from '@/types/visualisation';
import { useAtomValue } from 'jotai';
import { eventsUrl } from '@/query/url';
import { useTranslation } from 'react-i18next';
import { ScopeProvider } from 'jotai-scope';
import { tableColumnEntitiesAtom } from '@/atoms/queries/tableColumns';
import { useCsvDownload } from '@/hooks/exporting/useCsvDownload';
import { VisSectionContainer } from '@/components/layout/VisSectionContainer';
import { dataLockerVisualisationComponents } from '../visComponents';

const VisDisplaySectionWithControls = ({ pageTitle }: { pageTitle: string }) => {
  useTableColumns(['competition', 'season', 'game', 'team', 'player', 'event']);
  const filterParams = useAtomValue(eventDataFilterParamsAtom);
  useCsvDownload(pageTitle, eventsUrl(filterParams));

  return (
    <VisSectionContainer>
      <EventDataControls defaultDisplayMode={VisDisplayMode.TABLE} />
      <VisDisplaySection
        EventsTableComponent={DataLockerEventsTable}
        visualisationComponent={
          <PitchVisualisation
            visualisations={dataLockerVisualisationComponents}
            footerComponent={<EventCountInfoBar eventCountAtom={eventsDataCountAtom} />}
          />
        }
      />
    </VisSectionContainer>
  );
};

export const EventDataPage = () => {
  const filterParams = useAtomValue(eventDataFilterParamsAtom);
  useAutoResetPagination(filterParams);
  const { t } = useTranslation('navigation');
  const eventDataLockerPageTitle = t('pageTitles.dataLocker', { entity: t('event.one', { ns: 'entity' }) });

  return (
    <ScopeProvider atoms={[tableColumnEntitiesAtom]}>
      <PageTitle>{eventDataLockerPageTitle}</PageTitle>
      <DataLockerLayoutWithFilters>
        <VisDisplaySectionWithControls pageTitle={eventDataLockerPageTitle} />
      </DataLockerLayoutWithFilters>
    </ScopeProvider>
  );
};
