import { HeatMapBase } from '@/components/visualisations/HeatMap';
import { teamDefensiveActivityAtom, unwrappedTeamDefensiveActivityAtom } from '@/atoms/queries/team/teamEventData';
import { CardBody } from '@statsbomb/kitbag-components';
import { useStatus } from '@/hooks/useStatus';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { useAtomValue } from 'jotai';
import { CardHeightWrapper } from '@/components/card/CardHeightWrapper';

export const TeamDefensiveActivity = () => {
  const events = useAtomValue(unwrappedTeamDefensiveActivityAtom);
  const status = useLoadableStatus(teamDefensiveActivityAtom);
  const statusProps = useStatus('general', status);

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        <HeatMapBase eventsForMetric={events} />
      </CardBody>
    </CardHeightWrapper>
  );
};
