import { ReactNode } from 'react';
import { EventArrowLayer, EventMarkerLayer, PitchSelectionLayer, PitchViz } from '@statsbomb/kitbag-datavis';
import { useAtom, useAtomValue } from 'jotai';
import { EventMapBaseProps, EventMapProps, UnwrappedPitchEventsAtom } from '@/types/visualisation';
import { activeRectAtom, selectedVisEventsAtom } from '@/atoms/vis/selection';
import { useResetSelectionLayer } from '@/hooks/useResetSelectionLayer';
import { useNavigateToPreview } from '@/hooks/useNavigateToPreview';
import { useDragEndSelectEvents } from '@/hooks/useDragEndSelectEvents';
import { useCurrentlySelectedVideoId } from '@/hooks/useCurrentlySelectedVideoId';
import { usePitchCrop } from '@/hooks/usePitchCrop';
import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { showLegendAtom, showMarkerStartAtom } from '@/atoms/visualisation';
import { isNullish } from '@/utils/general';
import { useEventMarkerColour } from '@/hooks/eventMap/useEventMarkerColour';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { useStatus } from '@/hooks/useStatus';
import { CardBody } from '@statsbomb/kitbag-components';
import { PitchVisWithLegend } from './PitchVisualisation/PitchVisWrapper';
import { PitchVisWithAttackingDirectionArrow } from './PitchVisualisation/PitchVisWithAttackingDirectionArrow';
import { CardHeightWrapper } from '../card/CardHeightWrapper';

const SelectionLayerWrapper = ({
  unwrappedPitchEventsAtom,
  children,
}: {
  unwrappedPitchEventsAtom: UnwrappedPitchEventsAtom;
  children: ReactNode;
}) => {
  const resetSelectionLayer = useResetSelectionLayer();
  const [activeRect, setActiveRect] = useAtom(activeRectAtom);

  const onDragEnd = useDragEndSelectEvents(unwrappedPitchEventsAtom);

  return (
    <PitchSelectionLayer
      onDragStart={resetSelectionLayer}
      onDragEnd={onDragEnd}
      selectionLayerAttributes={activeRect}
      setSelectionLayerAttributes={setActiveRect}
    >
      {children}
    </PitchSelectionLayer>
  );
};

const EventMapBase = ({ allowInteraction, unwrappedPitchEventsAtom, isPreview = false }: EventMapBaseProps) => {
  const [selectedVisEvents, setSelectedEvents] = useAtom(selectedVisEventsAtom);
  const { markerEvents, arrowEvents } = useAtomValue(unwrappedPitchEventsAtom);
  const allEvents = [...markerEvents, ...arrowEvents];
  const resetSelectionLayer = useResetSelectionLayer();
  const navigateToPreview = useNavigateToPreview();

  const pitchRotation = useGetPitchRotation(isPreview);
  const visibleArea = usePitchCrop(isPreview);
  const showMarkerStart = useAtomValue(showMarkerStartAtom);
  const showLegend = useAtomValue(showLegendAtom);

  const currentlySelectedVideoId = useCurrentlySelectedVideoId();
  const getEventMarkerColour = useEventMarkerColour();
  const currentVideoEventId = [allEvents.find(item => item.videoEventId === currentlySelectedVideoId)?.eventId];

  const onMarkerClick: React.ComponentProps<typeof EventMarkerLayer>['onMarkerClick'] = allowInteraction
    ? (_, id) => {
        resetSelectionLayer();
        setSelectedEvents(allEvents.filter(({ eventId }) => eventId === id));

        navigateToPreview();
      }
    : undefined;

  const selectedItemIds = selectedVisEvents?.map(({ eventId }) => eventId);

  const EventMapLayers = (
    <>
      <EventMarkerLayer
        onMarkerClick={onMarkerClick}
        markers={markerEvents?.map(({ eventId: id, startX, startY, endX, endY, successFail, videoEventId, xg }) => {
          const x = showMarkerStart || isNullish(endX) ? startX : endX;
          const y = showMarkerStart || isNullish(endY) ? startY : endY;

          return {
            id,
            x,
            y,
            fill: getEventMarkerColour(videoEventId, successFail, xg),
            shapeName: 'CIRCLE',
          };
        })}
        selectedItemIds={selectedItemIds}
        highlightedItemIds={currentVideoEventId}
      />
      <EventArrowLayer
        onMarkerClick={onMarkerClick}
        arrows={arrowEvents?.map(({ eventId: id, startX, startY, endX, endY, successFail, videoEventId, xg }) => ({
          id,
          startX,
          startY,
          endX,
          endY,
          stroke: getEventMarkerColour(videoEventId, successFail, xg),
          arrowHeadName: 'EQUILATERAL',
        }))}
        selectedItemIds={selectedItemIds}
        highlightedItemIds={currentVideoEventId}
      />
    </>
  );

  const pitchViz = (
    <PitchViz
      /* this is intentionally empty to overwrite pitch viz svg styles
    to position the arrow correctly */
      className=""
      rotationName={pitchRotation}
      pitchFocusZoneName={visibleArea}
      overlay={
        allowInteraction ? (
          <SelectionLayerWrapper unwrappedPitchEventsAtom={unwrappedPitchEventsAtom}>
            {EventMapLayers}
          </SelectionLayerWrapper>
        ) : (
          EventMapLayers
        )
      }
    />
  );

  return (
    <PitchVisWithLegend>
      {/* Arrow direction is shown and hidden with the legend */}
      {showLegend ? (
        <PitchVisWithAttackingDirectionArrow pitchVisType="eventmap" pitchRotation={pitchRotation}>
          {pitchViz}
        </PitchVisWithAttackingDirectionArrow>
      ) : (
        pitchViz
      )}
    </PitchVisWithLegend>
  );
};

export const EventMap = ({ pitchEventsAtom, unwrappedPitchEventsAtom, allowInteraction, isPreview }: EventMapProps) => {
  const status = useLoadableStatus(pitchEventsAtom);
  const statusProps = useStatus('general', status);

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        <EventMapBase
          unwrappedPitchEventsAtom={unwrappedPitchEventsAtom}
          allowInteraction={allowInteraction}
          isPreview={isPreview}
        />
      </CardBody>
    </CardHeightWrapper>
  );
};
