import { Nullable } from '@/types/generic';
import { SelectedVideoIds, VideoDescription } from '@/types/video';
import { formatPlayerName } from '@/utils/player';
import { snakeToCamel } from '@/utils/queries';
import { usePlaylistCurrentlySelected } from '@statsbomb/video-client-js';
import { useTranslation } from 'react-i18next';

export const useFormatVideoEvents = (
  selectedVideoIds: Nullable<SelectedVideoIds[]>,
  descriptionsData: VideoDescription[],
) => {
  const { t } = useTranslation('events');
  const [currentlySelectedVideo] = usePlaylistCurrentlySelected();

  return selectedVideoIds?.map(({ eventId, videoEventId }) => {
    const eventData = descriptionsData.find(event => event.eventId === eventId);

    const coreVideoData = {
      id: videoEventId,
      selectedEntry: videoEventId === currentlySelectedVideo?.id,
    };

    if (!eventData) return coreVideoData;

    const playerName = formatPlayerName(eventData.playerName, eventData.playerNickname);
    const eventType = t(`fields.event.type.${snakeToCamel(eventData.type)}`);
    // Ensure mins/secs are two characters long - if not prefix with a zero (e.g 2 mins becomes 02)
    const timestamp = `${String(eventData.minute).padStart(2, '0')}:${String(eventData.second).padStart(2, '0')}`;
    const { gameName, competitionName, gameDate } = eventData;

    return {
      ...coreVideoData,
      description: `${playerName} - ${eventType}`,
      descriptionLineTwo: `${timestamp} - ${gameName}`,
      descriptionLineThree: `${competitionName} - ${gameDate}`,
    };
  });
};
