import { playerEventVideoIdsAtom, playerGameEventVideoDescriptionsAtom } from '@/atoms/queries/player/playerEventData';
import { useSetMetricKey } from '@/hooks/useMetricKey';
import { useSetPlayerId } from '@/hooks/usePlayerId';
import { useSetGameIdForVideos } from '@/hooks/useSetGameIdForVideos';
import { useAtomValue } from 'jotai';
import { MatchStatsVideoLayout } from '@/components/layout/video/MatchStatsVideoLayout';

export const PlayerGamesVideoPage = () => {
  useSetMetricKey();
  useSetPlayerId();
  useSetGameIdForVideos();

  const playerEventVideoIds = useAtomValue(playerEventVideoIdsAtom);
  const playerGameEventVideoDescriptions = useAtomValue(playerGameEventVideoDescriptionsAtom);

  return (
    <MatchStatsVideoLayout videoEventIds={playerEventVideoIds} descriptionsData={playerGameEventVideoDescriptions} />
  );
};
