import { Grid } from '@statsbomb/kitbag-components';
import { DashboardWidgetConfig } from '@/types/widget';
import { DashboardWidgetWrapper } from '../widget/DashboardWidgetWrapper';

export const DashboardWidgets = ({ entityId, widgets }: { entityId: number; widgets: DashboardWidgetConfig[] }) => (
  <Grid>
    {widgets.map(({ configId, definition }) => (
      <Grid item md={12} lg={6} xl={definition.vis === 'info' ? 8 : 4} key={configId}>
        <DashboardWidgetWrapper key={configId} {...definition} widgetId={configId} entityId={entityId} />
      </Grid>
    ))}
  </Grid>
);
