import { EventMap } from '@/components/visualisations/EventMap';
import { VisualisationComponentProps } from '@/types/visualisation';
import { MetricKeyFallbackWrapper } from '@/components/metric/MetricKeyFallbackWrapper';
import { teamPitchEventsAtom, teamUnwrappedPitchEventsAtom } from '@/atoms/vis/teamVis';

export const TeamEventMap = ({ allowInteraction, isPreview }: VisualisationComponentProps) => (
  <MetricKeyFallbackWrapper>
    <EventMap
      allowInteraction={allowInteraction}
      pitchEventsAtom={teamPitchEventsAtom}
      unwrappedPitchEventsAtom={teamUnwrappedPitchEventsAtom}
      isPreview={isPreview}
    />
  </MetricKeyFallbackWrapper>
);
