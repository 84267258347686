import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { Nullable } from '@/types/generic';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { UserConfig, Gender } from '@statsbomb/parachute-types';
import { fetchClientAtom } from '@/atoms/queries/client';
import { objSnakeToCamel } from '@/utils/queries';
import {
  UserConfigCamelCase,
  UserConfigReportDefinition,
  UserConfigFilterDefinition,
  UserConfigGenderDefinition,
  UserConfigSettingsDefinition,
  UserConfigType,
} from '@/types/userConfigs';
import { PromiseAtom } from '@/types/atom';
import { defaultTeamInfoAtom } from './user';

export const userConfigsAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['userConfigs'] as const;
  const queryFn = async () => {
    // filter out deleted configs
    const results = (await fetch(`/user/configs?null[deleted_at]=true`)) as UserConfig[];

    return results.map(objSnakeToCamel) as UserConfigCamelCase[];
  };

  return { queryKey, queryFn, staleTime: 0, refetchOnMount: true };
});

// singular config
const configByTypeAtom = (type: string) =>
  atom(async get => {
    const { data } = await get(userConfigsAtom);
    return data.find(config => (config.type as UserConfigType) === type) || null;
  });

// multiple configs
const configsByTypeAtom = <T>(type: string) =>
  atom(async get => {
    const { data } = await get(userConfigsAtom);
    return data.filter(config => (config.type as UserConfigType) === type) as T;
  });

export const reportConfigsAtom = configsByTypeAtom<UserConfigReportDefinition[]>('dashboard');
export const filterSetsAtom = configsByTypeAtom<UserConfigFilterDefinition[]>('filters');
export const genderConfigAtom = configByTypeAtom('gender') as PromiseAtom<UserConfigGenderDefinition | null>;
export const settingsConfigAtom = configByTypeAtom('settings') as PromiseAtom<UserConfigSettingsDefinition | null>;

/* I've used unwrap here as some places that use this configs atom need it synchronously or they behave
slightly weirdly. If you use the async atom when loading a filter set it jumps a bit while it's loading and 
this change to use the synchronous atom resolves this. */
export const unwrappedFilterSetsAtom = unwrap(filterSetsAtom, prev => prev || null);
export const unwrappedReportConfigsAtom = unwrap(reportConfigsAtom, prev => prev || []);

export const widgetGenderAtom = atom<Nullable<Gender>>(null);

export const genderAtom = atom(async get => {
  const widgetGender = get(widgetGenderAtom);

  // in the context of a widget use the widget version of gender atom
  if (widgetGender) {
    return widgetGender;
  }

  // use preferred gender if it was saved to user config
  const genderConfig = await get(genderConfigAtom);
  if (genderConfig) {
    return genderConfig.definition.gender;
  }

  // otherwise, use the user's default team gender
  const defaultTeamInfo = await get(defaultTeamInfoAtom);
  if (defaultTeamInfo) {
    return defaultTeamInfo.gender;
  }

  return undefined;
});

export const unwrappedGenderAtom = unwrap(genderAtom, prev => prev || undefined);
