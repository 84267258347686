import { playerPositionsAtom, unwrappedPlayerPositionsAtom } from '@/atoms/queries/players';
import { CardHeightWrapper } from '@/components/card/CardHeightWrapper';
import { PLAYED_90S_MINIMUM, PLAYED_90S_THRESHOLD } from '@/consts/player';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { usePositionUIElements } from '@/hooks/usePositionUIElements';
import { useStatus } from '@/hooks/useStatus';
import { CardBody, Position } from '@statsbomb/kitbag-components';
import { PitchViz, PositionSelectionLayer } from '@statsbomb/kitbag-datavis';
import { useAtomValue } from 'jotai';

const PlayerPositionsBase = ({ positions }: { positions: Record<Position, number> }) => {
  const positionUIElements = usePositionUIElements();

  const playerPositions = positionUIElements.map(uiPosition => {
    const played90s = positions[uiPosition.key];

    return {
      ...uiPosition,
      value: played90s?.toFixed(1) || undefined,
      isMuted: !played90s,
      isSelected: played90s > PLAYED_90S_THRESHOLD,
    };
  });

  return <PitchViz className="h-full w-full" overlay={<PositionSelectionLayer playerPositions={playerPositions} />} />;
};

const PlayerPositionsWithData = ({ positions }: { positions: Record<Position, number> }) => {
  const positionsToDisplay = Object.fromEntries(
    Object.entries(positions as Record<string, number>).filter(([_, played90s]) => played90s > PLAYED_90S_MINIMUM),
  );

  return <PlayerPositionsBase positions={positionsToDisplay} />;
};

export const PlayerPositions = () => {
  const unwrappedPlayerPositions = useAtomValue(unwrappedPlayerPositionsAtom);
  const status = useLoadableStatus(playerPositionsAtom);
  const statusProps = useStatus('general', status);

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        <PlayerPositionsWithData positions={unwrappedPlayerPositions} />
      </CardBody>
    </CardHeightWrapper>
  );
};
