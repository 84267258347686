import { metricKeyAtom } from '@/atoms/metric';
import { CloseCardHeaderButton } from '@/components/button/CloseCardHeaderButton';
import { EventCountInfoBar } from '@/components/visualisations/EventCountInfoBar';
import { VisualisationComponent } from '@/components/visualisations/PitchVisualisation/VisualisationComponent';
import { METRIC_KEY } from '@/consts/searchKeys';
import { useUpdateQueryString } from '@/hooks/useUpdateQueryString';
import { PromiseAtom } from '@/types/atom';
import { PitchVisComponents } from '@/types/visualisation';
import { CardHeader, Icon, Typography } from '@statsbomb/kitbag-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import { filtersToPersistAtom } from '@/atoms/filters/persistedFilters';
import { atomsToHydrateAtom } from '@/atoms/hydration';
import { getFilterAtomsToHydrate } from '@/utils/atoms/atomHydration';
import { Entity } from '@/types/entity';
import { EntityPreviewHeader } from './EntityPreviewHeader';

export const EntityPreview = ({
  visualisationComponents,
  eventsForMetricCountAtom,
  children,
  pathTo = '../../vis',
  entity,
}: {
  visualisationComponents: PitchVisComponents;
  eventsForMetricCountAtom: PromiseAtom<number>;
  children: React.ReactNode;
  pathTo?: string;
  entity: Entity;
}) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('general');
  const metricKey = useAtomValue(metricKeyAtom);
  const updateQueryString = useUpdateQueryString(searchParams);
  const newQueryString = updateQueryString([], [`${entity}Id`]);
  const filters = useAtomValue(filtersToPersistAtom);
  const setAtomsToHydrate = useSetAtom(atomsToHydrateAtom);

  return (
    metricKey && (
      <>
        <CardHeader>
          <EntityPreviewHeader />
          <CloseCardHeaderButton navigationPath="./.." paramsToDelete={[METRIC_KEY, `${entity}Id`]} />
        </CardHeader>

        <div className="lg:max-w-[600px] flex flex-col relative">
          <div className="relative">
            <Link
              to={`${pathTo}?${newQueryString}`}
              relative="route"
              className="absolute w-full h-full group z-10"
              onClick={() => {
                setAtomsToHydrate(getFilterAtomsToHydrate(filters));
              }}
            >
              <div className="absolute flex items-center gap-1 px-1 bg-canvas-secondary-main rounded-sb-full left-6 top-6 sm:opacity-0 group-hover:opacity-100 transition-opacity duration-300 max-w-[calc(100%-48px)]">
                <Icon variant="EnterFullscreen" size="small" colour="primary" />
                <Typography as="span" variant="bodyMedium">
                  {t('fullScreen')}
                </Typography>
              </div>
            </Link>
            <VisualisationComponent isPreview visualisations={visualisationComponents} />
          </div>
          <EventCountInfoBar eventCountAtom={eventsForMetricCountAtom} />
          {children}
        </div>
      </>
    )
  );
};
