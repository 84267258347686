import { playerIdAtom } from '@/atoms/player/player';
import { teamIdAtom } from '@/atoms/team/team';
import { EntityIdAtom, EntityVisComponent } from '@/types/entity';
import { dataLockerVisualisationComponents } from '@/pages/dataLocker/visComponents';
import { WidgetVisType } from '@/types/widget';
import { playerVisComponents, teamVisComponents } from './visualisationComponents';

export const entityIdAtoms: EntityIdAtom = {
  player: playerIdAtom,
  team: teamIdAtom,
};

export const entityVisualisationComponents: EntityVisComponent = {
  player: playerVisComponents,
  team: teamVisComponents,
  dataLocker: dataLockerVisualisationComponents,
};

export const NON_NAVIGABLE_WIDGETS: WidgetVisType[] = ['positionMap', 'shotMap', 'info', 'defensiveActivity'];
