import { BaseEvent } from '@/types/event';
import { EventMarker } from '@statsbomb/parachute-types';
import { isNullish } from './general';

export const convertEventMarkersToBaseEvents = (eventMarkers: EventMarker[]) => {
  const formattedEvents = eventMarkers
    .filter(event => !isNullish(event.start_x) && !isNullish(event.start_y))
    .map(event => ({
      startX: event.start_x,
      startY: event.start_y,
      eventId: event.event_id,
    }));

  return formattedEvents as BaseEvent[];
};
