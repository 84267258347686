import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';
import { VisualisationComponentProps, VisualisationMap } from '@/types/visualisation';
import { pitchCropAtom, selectedVisualisationAtom } from '@/atoms/visualisation';
import { useSearchParams } from 'react-router-dom';
import { visualisationConfigsAtom } from '@/atoms/queries/visualisation';
import { useGetPitchRotation } from '@/hooks/useGetPitchRotation';
import { twJoin } from 'tailwind-merge';
import { FallbackLoading } from '../FallbackLoading';

export const VisualisationComponentWithData = ({
  allowInteraction = false,
  visualisations,
  isPreview,
  rotationName,
  visibleArea,
}: VisualisationComponentProps & {
  visualisations: VisualisationMap;
}) => {
  const metricVisualisations = useAtomValue(visualisationConfigsAtom);
  const [selectedVisualisation, setSelectedVisualisation] = useAtom(selectedVisualisationAtom);
  const selectedVis = selectedVisualisation.visualisationKey;

  const [searchParams] = useSearchParams();
  const visQueryParam = searchParams.get('vis');
  useEffect(() => {
    if (visQueryParam) {
      setSelectedVisualisation(metricVisualisations.find(vis => vis.visualisationKey === visQueryParam)!);
    }
  }, []);

  const VisComponent = visualisations[selectedVis];
  const isHorizontal = rotationName === 'HORIZONTAL' || rotationName === 'HORIZONTAL_REVERSE';
  const isFullPitch = visibleArea === 'FULL_PITCH';

  return (
    <div
      className={twJoin((!isHorizontal || !isFullPitch) && '[&_svg]:max-h-[600px]')}
      data-testid={`vis-component-${selectedVis}`}
    >
      <VisComponent allowInteraction={allowInteraction} isPreview={isPreview} />
    </div>
  );
};

export const VisualisationComponent = ({
  allowInteraction,
  visualisations,
  isPreview = false,
}: VisualisationComponentProps & { visualisations: VisualisationMap }) => {
  const pitchRotation = useGetPitchRotation(isPreview);
  const visibleArea = useAtomValue(pitchCropAtom);

  return (
    <div data-testid={`vis-orientation-${pitchRotation}`} className="w-full mx-auto max-w-[1000px]">
      <div data-testid={`vis-crop-${visibleArea}`}>
        <Suspense fallback={<FallbackLoading isPreview={isPreview} />}>
          <VisualisationComponentWithData
            allowInteraction={allowInteraction}
            visualisations={visualisations}
            isPreview={isPreview}
            visibleArea={visibleArea}
            rotationName={pitchRotation}
          />
        </Suspense>
      </div>
    </div>
  );
};
