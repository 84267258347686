import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { atomWithDebounce } from '@/atoms/debounce';
import { pageAndOrderParamsAtom } from '@/atoms/general';
import { metricKeyAtom } from '@/atoms/metric';
import { playerIdAtom } from '@/atoms/player/player';
import { EVENT_MARKERS_LIMIT } from '@/consts/visualisations';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { convertFilterParamsToString, gameEventParams, hasEmptyInFilters } from '@/utils/api';
import {
  convertEventDataIntoVideoDescriptions,
  convertMarkerDataIntoMarkers,
  convertMarkerDataIntoVideoDescriptions,
  convertMarkerDataToShotMapMarkers,
  processEventDataAtom,
} from '@/utils/atoms/eventData';
import { EventMarker, EventWithRels } from '@statsbomb/parachute-types';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { playerMetricEventsUrl } from '@/query/url';
import { getVideoIdsFromEvents } from '@/utils/video';
import { selectedGameIdForVideosAtom } from '@/atoms/video';
import { createUnwrappedPitchEventsAtom } from '@/utils/atoms/vis';
import { convertEventMarkersToBaseEvents } from '@/utils/events';
import { fetchClientAtom } from '../client';

export const playerEventMetricFiltersDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
export const { debouncedValueAtom: playerEventMetricFiltersAtom } = playerEventMetricFiltersDebounceObject;

const playerRawEventMarkersAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventMarkersForMetric',
    get(metricKeyAtom),
    get(playerIdAtom),
    get(playerEventMetricFiltersAtom),
  ] as const;

  const queryFn = async ({ queryKey: [, metricKey, playerId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!playerId || !metricKey || hasEmptyInFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/player/${playerId}/metric/${metricKey}/event-markers${convertFilterParamsToString({
        ...filterParams,
        limit: EVENT_MARKERS_LIMIT,
      })}`,
    )) as Promise<EventMarker[]>;
  };

  return { queryKey, queryFn };
});

export const playerEventMarkersForMetricAtom = atom(async get => {
  const playerEventMarkersForMetricAtom = await get(playerRawEventMarkersAtom);
  return convertMarkerDataIntoMarkers(playerEventMarkersForMetricAtom.data);
});

export const unwrappedPlayerEventMarkersForMetricAtom = createUnwrappedPitchEventsAtom(playerEventMarkersForMetricAtom);

const playerRawEventsAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventsForMetric',
    get(metricKeyAtom),
    get(playerIdAtom),
    get(playerEventMetricFiltersAtom),
    get(pageAndOrderParamsAtom),
  ] as const;

  const queryFn = async ({
    queryKey: [, metricKey, playerId, filterParams, pageAndOrderParams],
  }: {
    queryKey: typeof queryKey;
  }) => {
    if (!playerId || !metricKey || hasEmptyInFilters(filterParams)) return [];
    const url = playerMetricEventsUrl(playerId, metricKey, {
      ...pageAndOrderParams,
      ...filterParams,
    });
    if (!url) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(url)) as Promise<EventWithRels[]>;
  };

  return { queryKey, queryFn };
});
export const playerEventsForMetricAtom = atom(async get => processEventDataAtom(await get(playerRawEventsAtom)));

const playerRawEventsCountAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventsForMetricCount',
    get(metricKeyAtom),
    get(playerIdAtom),
    get(playerEventMetricFiltersAtom),
  ] as const;

  const queryFn = async ({ queryKey: [, metricKey, playerId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!playerId || !metricKey || hasEmptyInFilters(filterParams)) return 0;
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/player/${playerId}/metric/${metricKey}/events/count${convertFilterParamsToString(filterParams)}`,
    )) as Promise<number>;
  };

  return { queryKey, queryFn };
});
export const playerEventsForMetricCountAtom = atom(async get => (await get(playerRawEventsCountAtom)).data);

const playerRawEventsForMetricInGameAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'playerEventsForMetricInGame',
    get(metricKeyAtom),
    get(playerIdAtom),
    get(selectedGameIdForVideosAtom),
  ] as const;

  const queryFn = async ({ queryKey: [, metricKey, playerId, gameId] }: { queryKey: typeof queryKey }) => {
    const url = playerMetricEventsUrl(playerId, metricKey, gameEventParams(gameId));
    if (!url || !gameId) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(url)) as Promise<EventWithRels[]>;
  };

  return { queryKey, queryFn };
});

export const playerEventVideoIdsAtom = atom(async get => {
  const processedEventData = processEventDataAtom(await get(playerRawEventsForMetricInGameAtom));

  return getVideoIdsFromEvents(processedEventData);
});

export const playerEventMarkersVideoDescriptionsAtom = atom(async get => {
  const playerEventMarkersForMetricAtom = await get(playerRawEventMarkersAtom);
  return convertMarkerDataIntoVideoDescriptions(playerEventMarkersForMetricAtom.data);
});

export const playerGameEventVideoDescriptionsAtom = atom(async get => {
  const playerRawEventsForMetricInGame = await get(playerRawEventsForMetricInGameAtom);
  return convertEventDataIntoVideoDescriptions(playerRawEventsForMetricInGame.data);
});

const rawPlayerShotMarkersAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['playerShotMarkers', get(playerIdAtom), get(playerEventMetricFiltersAtom)] as const;

  const queryFn = async ({ queryKey: [, playerId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!playerId || hasEmptyInFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/event-markers${convertFilterParamsToString({
        ...filterParams,
        limit: EVENT_MARKERS_LIMIT,
      })}&eq[event.player_id]=${playerId}&eq[event.type]=shot&neq[event.period]=5`,
    )) as Promise<EventMarker[]>;
  };

  return { queryKey, queryFn };
});

export const playerShotMarkersAtom = atom(async get => {
  const playerShotMarkers = await get(rawPlayerShotMarkersAtom);
  return convertMarkerDataToShotMapMarkers(playerShotMarkers.data);
});

export const unwrappedPlayerShotMarkersAtom = unwrap(playerShotMarkersAtom, prev => prev || []);

const rawPlayerDefensiveActivityAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['playerDefensiveActivity', get(playerIdAtom), get(playerEventMetricFiltersAtom)] as const;
  const queryFn = async ({ queryKey: [, playerId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!playerId || hasEmptyInFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/event-markers${convertFilterParamsToString({
        ...filterParams,
        limit: EVENT_MARKERS_LIMIT,
      })}&eq[event.player_id]=${playerId}&eq[event.attributes.defensive_action]=true`,
    )) as Promise<EventMarker[]>;
  };
  return { queryKey, queryFn };
});

export const playerDefensiveActivityAtom = atom(async get => {
  const playerDefensiveActivity = await get(rawPlayerDefensiveActivityAtom);
  return convertEventMarkersToBaseEvents(playerDefensiveActivity.data);
});

export const unwrappedPlayerDefensiveActivityAtom = unwrap(playerDefensiveActivityAtom, prev => prev || []);
