import { TEAM_RADAR_TEMPLATES } from '@/consts/radar';
import { PITCH_COLOURING_MODES } from '@/consts/visualisations';
import { DashboardWidgetConfig } from '@/types/widget';
import { atom } from 'jotai';

const defaultDisplaySettings = {
  isStartPlacement: null,
  pitchCrop: null,
  pitchOrientation: null,
  radarTemplateId: null,
  showArrows: false,
  pitchMarkerColouringMode: PITCH_COLOURING_MODES[0],
};

// TODO (PPC-1527: Temp hard coded values - will be loaded from API in due course)
const DASHBOARD_TEMPLATES = [
  {
    configId: '9582325e-64f0-49a9-82a2-aea4dfdba6b4',
    type: 'dashboard_template',
    definition: {
      entity: 'player',
      name: 'StatsBomb Default Player Template',
      widgetIds: [
        'd8044d71-5ae6-4341-a36c-24277a2bed53',
        '883e2ceb-9778-4a88-9d88-ff6397a5c9fc',
        '67ebd3d9-e8f3-48a2-b384-d21137a1cc94',
        '06fe36f4-ce9b-4241-bbd7-bbf7f3389701',
        'd4296a52-3dfe-49a8-878c-51d359d6e570',
      ],
    },
  },
  {
    configId: '30878933-e5bf-4ff0-942e-d057626a6df2',
    type: 'dashboard_template',
    definition: {
      entity: 'team',
      name: 'StatsBomb Default Team Template',
      widgetIds: [
        '3e490a03-312e-4dd7-a0f0-06fa8fa55695',
        'efeef338-c414-4ec3-a5bc-79b88d2ee0ee',
        '5a880ee3-962c-4018-9cdf-aaf7f0f1c46f',
        '4134d7f2-33fd-4bc7-8dd7-d0726c50732a',
      ],
    },
  },
];

const DASHBOARD_TEMPLATE_WIDGETS: DashboardWidgetConfig[] = [
  {
    configId: 'd8044d71-5ae6-4341-a36c-24277a2bed53',
    type: 'widget',
    definition: {
      vis: 'info',
      entity: 'player',
      displaySettings: defaultDisplaySettings,
    },
  },
  {
    configId: '883e2ceb-9778-4a88-9d88-ff6397a5c9fc',
    type: 'widget',
    definition: {
      vis: 'radar',
      entity: 'player',
      displaySettings: defaultDisplaySettings,
    },
  },
  {
    configId: '67ebd3d9-e8f3-48a2-b384-d21137a1cc94',
    type: 'widget',
    definition: {
      vis: 'positionMap',
      entity: 'player',
      displaySettings: defaultDisplaySettings,
    },
  },
  {
    configId: '06fe36f4-ce9b-4241-bbd7-bbf7f3389701',
    type: 'widget',
    definition: {
      vis: 'defensiveActivity',
      entity: 'player',
      displaySettings: defaultDisplaySettings,
    },
  },
  {
    configId: 'd4296a52-3dfe-49a8-878c-51d359d6e570',
    type: 'widget',
    definition: {
      vis: 'shotMap',
      entity: 'player',
      displaySettings: {
        ...defaultDisplaySettings,
        isStartPlacement: true,
        pitchCrop: 'OPPOSITION_BOX',
        pitchOrientation: 'VERTICAL',
      },
    },
  },
  {
    configId: 'efeef338-c414-4ec3-a5bc-79b88d2ee0ee',
    type: 'widget',
    definition: {
      vis: 'radar',
      entity: 'team',
      displaySettings: {
        ...defaultDisplaySettings,
        radarTemplateId: TEAM_RADAR_TEMPLATES[0].id,
      },
    },
  },
  {
    configId: '5a880ee3-962c-4018-9cdf-aaf7f0f1c46f',
    type: 'widget',
    definition: {
      vis: 'radar',
      entity: 'team',
      displaySettings: {
        ...defaultDisplaySettings,
        radarTemplateId: TEAM_RADAR_TEMPLATES[1].id,
      },
    },
  },
  {
    configId: '3e490a03-312e-4dd7-a0f0-06fa8fa55695',
    type: 'widget',
    definition: {
      vis: 'shotMap',
      entity: 'team',
      displaySettings: {
        ...defaultDisplaySettings,
        isStartPlacement: true,
        pitchCrop: 'OPPOSITION_BOX',
        pitchOrientation: 'VERTICAL',
      },
    },
  },
  {
    configId: '4134d7f2-33fd-4bc7-8dd7-d0726c50732a',
    type: 'widget',
    definition: {
      vis: 'defensiveActivity',
      entity: 'team',
      displaySettings: defaultDisplaySettings,
    },
  },
];

const dashboardTemplatesAtom = atom(DASHBOARD_TEMPLATES);
const dashboardWidgetsAtom = atom(DASHBOARD_TEMPLATE_WIDGETS);

export const playerDashboardWidgetsAtom = atom<DashboardWidgetConfig[]>(get => {
  const dashboardTemplates = get(dashboardTemplatesAtom);
  const dashboardWidgets = get(dashboardWidgetsAtom);
  const playerDashboardWidgetIds = dashboardTemplates
    .filter(dashboardTemplate => dashboardTemplate.definition.entity === 'player')
    .flatMap(playerDashboardTemplates => playerDashboardTemplates.definition.widgetIds);

  return dashboardWidgets.filter(dashboardTemplateWidget =>
    playerDashboardWidgetIds.includes(dashboardTemplateWidget.configId),
  );
});

export const teamDashboardWidgetsAtom = atom<DashboardWidgetConfig[]>(get => {
  const dashboardTemplates = get(dashboardTemplatesAtom);
  const dashboardWidgets = get(dashboardWidgetsAtom);
  const teamDashboardWidgetIds = dashboardTemplates
    .filter(dashboardTemplate => dashboardTemplate.definition.entity === 'team')
    .flatMap(teamDashboardTemplates => teamDashboardTemplates.definition.widgetIds);

  return dashboardWidgets.filter(dashboardTemplateWidget =>
    teamDashboardWidgetIds.includes(dashboardTemplateWidget.configId),
  );
});
