import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useStatus } from '@/hooks/useStatus';
import { CardBody, Table, Typography } from '@statsbomb/kitbag-components';
import { FlagIcon } from '@/components/icons/FlagIcon';
import { Player } from '@statsbomb/parachute-types';
import { KeysToCamelCase } from '@/types/generic';
import { CardHeightWrapper } from '@/components/card/CardHeightWrapper';
import { playerInfoAtom, unwrappedPlayerInfoAtom } from '@/atoms/player/info';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { TableCell } from '../../tables/TableCell';
import { PlayerFiltersSummary } from '../radar/PlayerFiltersSummary';

const PlayerInfoTable = ({ playerInfo }: { playerInfo?: KeysToCamelCase<Player> }) => {
  const { t } = useTranslation(['playerInfo', 'countries', 'events']);

  const name = playerInfo?.name || '';
  const dateOfBirth = playerInfo?.dateOfBirth && playerInfo?.age ? `${playerInfo.dateOfBirth} (${playerInfo.age})` : '';
  const height = playerInfo?.height ? `${playerInfo.height}cm` : '';
  const preferredFoot = playerInfo?.preferredFoot
    ? t(`fields.player.preferredFoot.${playerInfo.preferredFoot.toLowerCase()}`, { ns: 'events' })
    : '';
  const nationalityAreaCode = playerInfo?.nationalityAreaCode ? (
    <span className="flex gap-2 items-center">
      <FlagIcon countryCode={playerInfo.nationalityAreaCode} />
      {t(playerInfo.nationalityAreaCode.toLowerCase(), { ns: 'countries' })}
    </span>
  ) : (
    ''
  );

  const info = [
    { key: 'name', value: name },
    { key: 'nationalityAreaCode', value: nationalityAreaCode },
    { key: 'dateOfBirth', value: dateOfBirth },
    { key: 'height', value: height },
    { key: 'preferredFoot', value: preferredFoot },
  ];

  return (
    <Table>
      <Table.Body>
        {info.map(({ key, value }) => (
          <Table.Row key={`player-info-${key}`}>
            <TableCell className="w-28">{t(key, { ns: 'playerInfo' })}</TableCell>
            <TableCell>
              <Typography variant="headingSmall" as="span">
                {value}
              </Typography>
            </TableCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export const PlayerInfo = () => {
  const playerInfo = useAtomValue(unwrappedPlayerInfoAtom);
  const status = useLoadableStatus(playerInfoAtom);
  const statusProps = useStatus('general', status);

  return (
    <CardHeightWrapper>
      <CardBody {...statusProps}>
        <div className="flex flex-col gap-2 @container">
          <PlayerInfoTable playerInfo={playerInfo} />
          <PlayerFiltersSummary />
        </div>
      </CardBody>
    </CardHeightWrapper>
  );
};
