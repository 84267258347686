import React, { Suspense, useEffect, useState } from 'react';
import { ConfirmationDialog, TextField } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue } from 'jotai';
import { widgetIdAtom, widgetIdToEditAtom } from '@/atoms/report/widget';
import { useTranslation } from 'react-i18next';
import { unwrappedReportWidgetsToRenderAtom } from '@/atoms/report/reportSaving';
import { useParams } from 'react-router-dom';
import { patchUserConfigAtom } from '@/atoms/mutations/userConfigs/userConfigs';

const EditWidgetTitleModalWithData = () => {
  const widgetId = useAtomValue(widgetIdAtom);

  const { t } = useTranslation(['general', 'widget']);

  const reportWidgets = useAtomValue(unwrappedReportWidgetsToRenderAtom);
  const [widgetIdToEdit, setWidgetIdToEdit] = useAtom(widgetIdToEditAtom);
  const [titleInput, setTitleInput] = useState('');
  const isOpen = widgetIdToEdit !== null && widgetId === widgetIdToEdit;

  const { mutateAsync: patchUserConfig } = useAtomValue(patchUserConfigAtom);
  const { reportId } = useParams();

  const selectedWidgetIndex = reportWidgets.findIndex(widget => widget.widgetId === widgetIdToEdit);
  const selectedWidget = reportWidgets[selectedWidgetIndex];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setTitleInput(e.target.value);

  const handleConfirm = async () => {
    const newWidgetConfig = {
      ...selectedWidget,
      title: titleInput,
    };
    const updatedReportToSave = reportWidgets.with(selectedWidgetIndex, newWidgetConfig);

    // TODO (PPC-1458: remove the istanbul-ignore and handle edit widget error)
    /* istanbul ignore else */
    if (reportId) {
      await patchUserConfig([{ configId: reportId, configDefinition: { widgets: updatedReportToSave } }]);
    }

    setWidgetIdToEdit(null);
  };

  // auto populate/clear the title input
  useEffect(() => {
    setTitleInput(selectedWidget?.widgetId ? selectedWidget.title : '');
  }, [selectedWidget?.widgetId]);

  return (
    isOpen && (
      <ConfirmationDialog
        id="edit-widget-title"
        title={t('editTitle', { ns: 'widget' })}
        isOpen
        onConfirm={handleConfirm}
        onCancel={() => setWidgetIdToEdit(null)}
        cancelLabel={t('cancel')}
        confirmLabel={t('save')}
        isDisabledConfirm={!titleInput}
      >
        <TextField
          id="edit-title-input"
          label="Label"
          labelPosition="none"
          onChange={handleChange}
          value={titleInput}
        />
      </ConfirmationDialog>
    )
  );
};

export const EditWidgetTitleModal = () => (
  <Suspense>
    <EditWidgetTitleModalWithData />
  </Suspense>
);
