import { useAtomValue } from 'jotai';
import { useSetMetricKey } from '@/hooks/useMetricKey';
import { useSetGameIdForVideos } from '@/hooks/useSetGameIdForVideos';
import { teamEventVideoIdsAtom, teamGameEventVideoDescriptionsAtom } from '@/atoms/queries/team/teamEventData';
import { useSetTeamId } from '@/hooks/useTeamId';
import { MatchStatsVideoLayout } from '@/components/layout/video/MatchStatsVideoLayout';

export const TeamGamesVideoPage = () => {
  useSetMetricKey();
  useSetTeamId();
  useSetGameIdForVideos();

  const teamEventVideoIds = useAtomValue(teamEventVideoIdsAtom);
  const teamGameEventVideoDescriptions = useAtomValue(teamGameEventVideoDescriptionsAtom);

  return <MatchStatsVideoLayout videoEventIds={teamEventVideoIds} descriptionsData={teamGameEventVideoDescriptions} />;
};
