import { Suspense, useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { setAtomsToHydrateAtom } from '@/atoms/hydration';
import { DashboardWidgetVis } from '@/types/widget';
import { getDisplayAtomsToHydrate } from '@/utils/atoms/atomHydration';
import { entityVisualisationComponents } from '@/consts/widget';
import { useWidgetNavigationPath } from '@/hooks/useWidgetNavigationPath';
import { showLegendAtom } from '@/atoms/visualisation';
import { useRadarTemplateName } from '@/hooks/useRadarTemplateName';
import { DashboardWidget } from './DashboardWidget';
import { DashboardWidgetScopeProvider } from './DashboardWidgetScopeProvider';

const WidgetWrapperWithData = ({
  metric,
  entity,
  entityId,
  vis,
  widgetId,
  displaySettings,
  showLegend = false,
}: DashboardWidgetVis) => {
  const { t } = useTranslation('visualisation');
  const radarTemplateName = useRadarTemplateName();
  const title = `${t(vis)}${vis === 'radar' ? ` - ${radarTemplateName}` : ''}`;
  const navigationPath = useWidgetNavigationPath(entity, entityId, vis, metric, displaySettings.radarTemplateId);
  const Component = entityVisualisationComponents[entity]?.[vis]!;

  // Hydrate the widget
  const displayAtomsToHydrate = getDisplayAtomsToHydrate(displaySettings);
  const setAtomsToHydrate = useSetAtom(setAtomsToHydrateAtom);
  const setShowLegendAtom = useSetAtom(showLegendAtom);
  useEffect(() => {
    setAtomsToHydrate(displayAtomsToHydrate);
    setShowLegendAtom(showLegend);
  }, []);

  return (
    <DashboardWidget title={title} navigationPath={navigationPath}>
      <Component isWidget id={widgetId} />
    </DashboardWidget>
  );
};

export const DashboardWidgetWrapper = (props: DashboardWidgetVis) => (
  <Suspense>
    <DashboardWidgetScopeProvider>
      <WidgetWrapperWithData {...props} />
    </DashboardWidgetScopeProvider>
  </Suspense>
);
