import { CardBody, Table, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { reportConfigsAtom } from '@/atoms/queries/userConfigs';
import dayjs from 'dayjs';
import { useLoadableStatus } from '@/hooks/useLoadableStatus';
import { DATE_FORMAT } from '@/consts/date';
import { Link } from 'react-router-dom';
import { UserConfigMenu } from '@/components/menu/UserConfigMenu/UserConfigMenu';
import { reportIdToDeleteAtom, reportNameAtom, isRenameReportModalOpenAtom } from '@/atoms/report/report';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { useSortTable } from '@/hooks/useSortTable';
import { useSortObjectArray } from '@/hooks/useSortObjectArray';
import { useHydrateAtoms } from 'jotai/utils';
import { orderByAtom, orderDirectionAtom } from '@/atoms/table';
import { SORT_DIRECTION } from '@/types/ordering';
import { useReportsWithFallback } from '@/hooks/useReportsWithFallback';
import { REPORTS_TABLE_HEADERS } from '@/consts/report';
import { useStatus } from '../../../hooks/useStatus';
import { TableWrapper } from '../../tables/TableWrapper';
import { TableCell } from '../../tables/TableCell';

const ReportsTableBase = () => {
  // initialize the table order by and direction
  useHydrateAtoms([
    [orderByAtom, REPORTS_TABLE_HEADERS[0].sortKey],
    [orderDirectionAtom, SORT_DIRECTION.ASC],
  ]);

  const {
    t,
    i18n: { language },
  } = useTranslation('report');

  const reports = useReportsWithFallback();
  const { orderBy, orderDirection, handleSort, getSortedState } = useSortTable();
  const orderedReports = useSortObjectArray(orderBy, reports, orderDirection);

  const setReportName = useSetAtom(reportNameAtom);
  const setIsRenamingModalOpen = useSetAtom(isRenameReportModalOpenAtom);
  const setReportIdToDeleteAtom = useSetAtom(reportIdToDeleteAtom);

  return (
    <TableWrapper classes="z-0 !m-0">
      <div className="overflow-x-auto">
        {/* TODO (KC-350: Table variant with non alternating row colours) */}
        <Table withBorder={false}>
          <Table.Head>
            <Table.Row>
              {REPORTS_TABLE_HEADERS.map(header => (
                <Table.HeaderCell
                  key={header.sortKey}
                  size="regular"
                  sortState={getSortedState(header.sortKey)}
                  sortCb={() => handleSort(header.sortKey)}
                >
                  {t(header.translation, { ns: header.ns, count: reports.length })}
                </Table.HeaderCell>
              ))}
              <Table.HeaderCell />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {orderedReports.map(report => {
              const id = report.configId;
              const navigationPath = `/${language}/report/${id}`;

              return (
                /* TODO (KC-351: Hoverable table rows) */
                <Table.Row key={id} data-testid={`report-row-${id}`}>
                  <TableCell>
                    <Link to={navigationPath}>
                      <Typography variant="linkMedium" as="span">
                        {report.definition.name}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography as="span" variant="bodyRegular">
                      {dayjs(report.updatedAt).format(DATE_FORMAT)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-end">
                      <UserConfigMenu
                        configId={id}
                        onRenameClick={(reportName: string) => {
                          setReportName(reportName);
                          setIsRenamingModalOpen(true);
                        }}
                        onDeleteClick={(id: string, name: string) => {
                          setReportIdToDeleteAtom(id);
                          setReportName(name);
                        }}
                      />
                    </div>
                  </TableCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </TableWrapper>
  );
};

export const ReportsTable = () => {
  const tableStatus = useLoadableStatus(reportConfigsAtom);
  const statusProps = useStatus('reports', tableStatus);

  return (
    <CardBody {...statusProps}>
      <TableScopeProvider>
        <ReportsTableBase />
      </TableScopeProvider>
    </CardBody>
  );
};
