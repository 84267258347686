import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Typography } from '@statsbomb/kitbag-components';

interface DashboardWidgetProps {
  children: React.ReactNode;
  title: string;
  navigationPath: string;
}

const WidgetHeading = ({ title }: { title: string }) => (
  <Typography variant="headingMedium" as="h3" className="truncate" title={title}>
    {title}
  </Typography>
);

export const DashboardWidget = ({ title, children, navigationPath }: DashboardWidgetProps) => (
  <Card>
    <CardHeader>
      {navigationPath ? (
        <Link to={navigationPath} className="flex flex-1 gap-1 overflow-hidden hover:underline">
          <WidgetHeading title={title} />
        </Link>
      ) : (
        <WidgetHeading title={title} />
      )}
    </CardHeader>
    {children}
  </Card>
);
