import { VideoProvider, Playlist, VideoEvent } from '@statsbomb/video-client-js';
import { SelectedVideoIds, VideoDescription } from '@/types/video';
import { Nullable } from '@/types/generic';
import { useFormatVideoEvents } from '@/hooks/useFormatVideoEvents';
import { WideFirstColumnLayout } from '../WideFirstColumnLayout';

export const MatchStatsVideoLayout = ({
  videoEventIds,
  descriptionsData,
}: {
  videoEventIds: Nullable<SelectedVideoIds[]>;
  descriptionsData: VideoDescription[];
}) => {
  const videoEvents = useFormatVideoEvents(videoEventIds, descriptionsData);

  return (
    <div className="h-full video-column-order [&>div]:h-full [&_[data-column='1']>div]:pt-0">
      <VideoProvider>
        <WideFirstColumnLayout>
          <Playlist />
          <VideoEvent autoPlay events={videoEvents} />
        </WideFirstColumnLayout>
      </VideoProvider>
    </div>
  );
};
